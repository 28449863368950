import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { LoaderService } from "app/shared/loader.service";
import { environment } from "environments/environment";
import { DataService } from "../data.service";

@Component({
    selector: 'add-class-modal',
    templateUrl: './add-class-modal.component.html'
})

export class AddClassModal {
    classLink: string;
    classData: any;
    error: string;

    constructor(
        private modal: ModalController,
        public loaderService: LoaderService,
        public data: DataService,
        public http: HttpClient
    ) {}

    addClass() {
        this.error = null;
        this.loaderService.showLoading();

        if (!this.classLink) {
            this.error = 'Please provide a class link';
            this.loaderService.hideLoading();
            return;
        }

        const classSplit = this.classLink.split('/');
        const classId = classSplit.length > 0 ? classSplit[classSplit.length - 1] : null;
        const regex = '[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}';
        console.log('Class id', classId);
        if (!classId || !classId.match(regex)) {
            this.error = 'Please add a valid class link';
            this.loaderService.hideLoading();
            return;
        }

        this.http.get<any>(`${environment.NApiDomain}/get-class/${classId}`).subscribe(result => {
            if(result.success) {
                this.classData = result.data;
                if (this.classData.StudentPaid || this.classData.OrgStudentPaid) {
                    this.loaderService.hideLoading();
                    this.error = 'This class is student paid. Currently we don\'t support payments for clever users.';
                } else if (this.classData.isArchive) {
                    this.loaderService.hideLoading();
                    this.error = 'This class is archived and can\'t be added. Please contact your instructor';
                } else {
                    this.data.classes.subscribe(list => {
                        const isEnrolled = !!list.find(({IdGuid}) => IdGuid.toLowerCase() === classId.toLowerCase());
                        if (isEnrolled) {
                            this.error = 'You are already enrolled in this class.';
                            this.loaderService.hideLoading();
                            return;
                        }

                        const data = {
                            classId: this.classData.classId.toLowerCase(),
                            user: {
                                token: this.data.token,
                                classData: this.classData,
                                clever: true
                            }
                        }

                        console.log('Adding this class to the student\'s account.', data);
                        const req = this.http.post<any>(`${environment.NApiDomain}/add-class`, data);
                        req.subscribe(response => {
                            if (response.success) {
                                this.loaderService.hideLoading();
                                this.modal.dismiss(true);
                            } else if (response.error) {
                                this.error = response.error;
                                this.loaderService.hideLoading();
                            } else {
                                this.error = 'Unkown error while adding a class to your account.';
                                this.loaderService.hideLoading();
                            }
                        });
                    });
                }
            } else {
                this.error = result.error;
                this.loaderService.hideLoading();
            }
        }, error => {
            this.error = 'Class not found. Make sure you copied the link correctly.';
            this.loaderService.hideLoading();
            console.log(error);
        });
    }

    closeModal() {
        this.modal.dismiss(false);
    }
}
