import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularFireModule, } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { ToolbarComponent } from './toolbar.component';
import { AppsDownloadComponent } from './apps-download.component';
import { environment } from 'environments/environment';
import { AccountComponent } from './account.component';
import { MarketingFooterComponent } from './marketing-footer.component';
import { Pricing } from './pricing';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SettingsService } from './settings.service';
import { SubscriptionTableComponent } from './subscription-table.component';
import { AccountTypeService } from './account-type.service';
import { PayForClassComponent } from './pay-for-class/pay-for-class.component';
import { LicensesService } from 'app/new-student/licenses.service';
import { IonicModule } from '@ionic/angular';
import { DeviceService } from './device.service';
import { SuccessfulPaymentComponent } from './successful-payment.component';
import { MatTabsModule } from '@angular/material/tabs';
import { LoaderService } from './loader.service';
import { LoaderComponent } from './loader.component';
import { SyncRoomTaskDetails } from './sync-room-task.component';
import { SyncRoomTaskPopupComponent } from './sync-room-task-popup.component';
import { StopCheatingPopupComponent } from './stop-cheating-popup';
import { User } from './user.service';
import { InProgressComponent } from './in-progress.component';
import { PrettySecondsPipe } from './pretty-seconds.pipe';
import { MediaRecorderComponent } from './record/media-recorder.component';
import { RecordingOverlayService } from './record/recording-overlay.service';
import { CustomMediaPlayerComponent } from './custom-media-player.component';
import { MatSelectLanguageComponent } from './mat-select-language.component';
import { MatEdlinkChooseUserComponent } from './mat-edlink-choose-user.component';
import { HMinSecPipe } from './h-min-sec.pipe';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { InstructorStudentFooterComponent } from './instructor-student-footer.component';
import { UserFriendlyTextPipe } from './user-friendly-text.pipe';
import { UserSessionService } from './user-session.service';
import { MatQuestionLimitations } from './mat-question-limitations';
import { MatDialogModule } from '@angular/material/dialog';
import { CustomDatePipe } from './custom-date.pipe';

@NgModule({
    imports: [
        CommonModule,
        AngularFireModule.initializeApp(environment.FirebaseConfig),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        RouterModule,
        FormsModule,
        MatExpansionModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatProgressBarModule,
        IonicModule,
        MatTabsModule,
        MatDialogModule,
        RecaptchaModule,
        YouTubePlayerModule
    ],
    exports: [
        ToolbarComponent,
        AppsDownloadComponent,
        AccountComponent,
        MarketingFooterComponent,
        InstructorStudentFooterComponent,
        MatExpansionModule,
        SubscriptionTableComponent,
        PayForClassComponent,
        RecaptchaModule,
        SyncRoomTaskDetails,
        StopCheatingPopupComponent,
        InProgressComponent,
        MediaRecorderComponent,
        CustomMediaPlayerComponent,
        PrettySecondsPipe,
        HMinSecPipe,
        UserFriendlyTextPipe,
        CustomDatePipe
    ],
    declarations: [
        ToolbarComponent,
        AppsDownloadComponent,
        AccountComponent,
        MarketingFooterComponent,
        InstructorStudentFooterComponent,
        SubscriptionTableComponent,
        PayForClassComponent,
        SuccessfulPaymentComponent,
        LoaderComponent,
        SyncRoomTaskDetails,
        SyncRoomTaskPopupComponent,
        StopCheatingPopupComponent,
        InProgressComponent,
        MediaRecorderComponent,
        CustomMediaPlayerComponent,
        PrettySecondsPipe,
        HMinSecPipe,
        CustomDatePipe,
        UserFriendlyTextPipe,
        MatSelectLanguageComponent,
        MatEdlinkChooseUserComponent,
        MatQuestionLimitations
    ],
    providers: [
        Pricing,
        SettingsService,
        AccountTypeService,
        LicensesService,
        DeviceService,
        LoaderService,
        User,
        UserSessionService,
        RecordingOverlayService,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: environment.captchaSiteKey, } as RecaptchaSettings
        }
    ],
    entryComponents: [SyncRoomTaskPopupComponent]
})
export class SharedModule { }
