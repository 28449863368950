
import { of as observableOf, timer as observableTimer, combineLatest as observableCombineLatest, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../shared/auth.service';
import { switchMap, map, shareReplay, startWith } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { environment } from 'environments/environment';
import { LoaderService } from 'app/shared/loader.service';
import { DeviceService } from 'app/shared/device.service';
import * as moment from 'moment';
import * as shuffle from 'lodash.shuffle';

export interface AllData {
    classData: any[];
    assessmentData: Assessment[];
    questionData: Question[];
    choiceData: Choice[];
    attemptData: Attempt[];
    feedbackData: Feedback[];
    preferencesData: Preferences[];
    rubricData: Rubric[];
}
export class Feedback {
    UserId: number;
    AttemptId: number;
    QuestionId: number;
    SkillId: number;
    FeedbackId: number;
    Date: string;
    Message: string;
    Title: string;
    SelectedValue: number;
    UniqueKey: string;
    Duration: string;
    ContentType: string;
    MediaConvertStatus: string;
    GradingRubricItemId: number;
}
export class Assessment {
    ClassGuid: string;
    Id: number;
    Name: string;
    Description: string;
    SkillAreaId: number;
    StartDate: string;
    StartTime: number;
    EndDate: string;
    EndTime: number;
    GradingRubricId: number;
    IsActive: boolean;
    IsGradable: boolean;
    IsPublished: boolean;
    AssessmentType: boolean;
    SynchronousGroup: boolean;
    RoomId: number;
    RoomName: string;
    SyncRoomDuration: number;
    RoomSid: string;
    RoomEnded: string;
    CompositionSid: string;
    CompositionStatus: number;
    InstructorStudent: boolean;
    UserId: string;
    IsRandomQuestions: boolean;
    EnablePlaybackControls: boolean;
    Extension: number;
    hasMultipleChoiceQuestions: boolean;
    ShowOrder: number;
    RTL: boolean;
    OngoingAssessment: boolean;

    // Metadata I calculate/ attach
    questions?: any[];
    score?: number;
    maxScore?: number;
    percentage?: number;
    partiallyGraded?: boolean;
    NumberOfStudentsPerRoom?: number;

    isGraded?: boolean;
    scores?: Scores;
    submitted?: boolean;

    syncRoomParticipants?: any[];
    SyncRoomVersion?: number;
}
export class Scores {
    maxScore: number;
    total: number;
    inPercents: number;
}
export class Question {
    AttemptStatus: number;
    ContentType?: string;
    Date: string;
    Duration: string;
    EndDate?: string;
    Extension: string;
    Id: number;
    Instructions?: string;
    IsRespNecessary: boolean;
    Name: string;
    Description: string;
    Objective?: string;
    ShowOrder: number;
    SkillId: number;
    StartDate?: string;
    UniqueKey: string;
    imageUrl?: string;
    IsMultipleChoice: boolean;
    Choices: Choice[];
    ReviewTime: number;
    ResponseTime: number;
    IsReRecording: boolean;
    NumberOfAttempts: number;
    RecordingsMade: number;
    MultipleChoicePoints: number;
    IsCorrect: boolean;
    videoUrl: string;
    hasVideo: boolean;
    audioUrl: string;
    hasAudio: boolean;
    HideQuestion: boolean;
    MinimumResponse: number;
    ResponseType: number;
    MediaConvertStatus?: string;
    ReplayMedia: boolean;
    APMode: boolean;
    APDirestions: string;
    APIntroduction: string;
    YoutubeVideoId: string;
    YTStartTime: number;
    YTEndTime: number;
    RubricId: number;

    // Manually added
    // @TODO add Type for feedback
    feedback?: any;
    attempt: Attempt;
    RTL: boolean;
    number: number;
    QuestionMediaFilesNotReady: boolean;
}
export class Choice {
    Content: ChoiceContent;
    CorrectAnswer: boolean;
    ExerciseId: number;
    Id: number;
    Text: string;
}
export class ChoiceContent {
    Extension: string;
    UniqueKey: string;
    MediaConvertStatus: string;
    type?: string;
    url?: string;
}
export class Attempt {
    Id: number;
    TaskId: number;
    UserId: number;
    Date: string;
    UniqueKey: string;
    Duration: string;
    WordCount: number;
    ContentType: string;
    MediaConvertStatus: string;

    // Manually added
    url?: string;
    RTL: boolean;
}
export class Preferences {
    NotifyOnAssessmentAvailable: boolean;
    NotifyOnAssessmentDue: boolean;
    NotifyOnFeedbackAdded: boolean;
    NotifyOnRoomsSubmissions: boolean;
    UserName: string;
}
export class Rubric {
    GradingRubricId: number;
    Title: string;
    Description: string;
    CriterionValue: number;
    RTL: boolean;
    QuestionId: number;
    NewRubrics: boolean;
}

@Injectable()
export class DataService {
    rawData: AllData;
    data: Observable<AllData>;
    classes: Observable<any[]>;
    assessments: Observable<Assessment[]>;
    questions: Observable<any[]>;
    choices: Observable<Choice[]>;
    preferences: Observable<Preferences[]>;
    rubric: Observable<Rubric[]>;
    feedback: Observable<Feedback[]>;
    token: string;
    refresher = new Subject<null>();
    previousQuestion: any;
    flag: number = 0;

    languages: any[] = [
        { bcp47: 'es', internalName: 'Keyboard_spanish', englishName: 'Spanish', vernacularName: 'español' },
        { bcp47: 'fr', internalName: 'Keyboard_french', englishName: 'French', vernacularName: 'français' },
        { bcp47: 'de', internalName: 'Keyboard_basic_kbdgr', englishName: 'German, Standard', vernacularName: 'Deutsch' },
        { bcp47: 'ar', internalName: 'Keyboard_basic_kbda1', englishName: 'Arabic', vernacularName: 'Arabic (101) Basic' },
        { bcp47: 'is', internalName: 'Keyboard_icelandic', englishName: 'Icelandic', vernacularName: 'Icelandic' },
        { bcp47: 'ksw-mymr', internalName: 'Keyboard_sil_sgaw_karen', englishName: "Karen, S'gaw", vernacularName: 'Sgaw Karen' },
        { bcp47: 'ln', internalName: 'Keyboard_sil_eastern_congo', englishName: 'Lingala', vernacularName: 'Eastern Kongo' },
        { bcp47: 'din_latn', internalName: 'Keyboard_dinka', englishName: 'Dinka', vernacularName: 'Thuɔŋjäŋ' },
        { bcp47: 'fj', internalName: 'Keyboard_el_pasifika', englishName: 'Fijan', vernacularName: 'Pasifika' },
        { bcp47: 'mnk-latn', internalName: 'Keyboard_sil_pan_africa_mnemonic', englishName: 'Mandinka', vernacularName: 'Pan Africa Mnemonic (SIL)' },
        { bcp47: 'fvr-latn', internalName: 'Keyboard_sil_tchad', englishName: 'Fur', vernacularName: 'Tchad' },
        { bcp47: 'kri-latn', internalName: 'Keyboard_libtralo', englishName: 'Krio', vernacularName: 'LIBTRALO' },
        { bcp47: 'th', internalName: 'Keyboard_basic_kbdth0', englishName: 'Thai', vernacularName: 'Thai' },
    ];

    // Setup streams for all student data for current token
    constructor(
        public auth: AuthService,
        public http: HttpClient,
        private loaderService: LoaderService,
        private deviceService: DeviceService
    ) {
        this.data = this.auth.token.pipe(switchMap(token => {
            if (token) {
                // Save a synced copy of the token
                this.token = token;

                return combineLatest(
                    this.refresher.pipe(startWith(null))).pipe(
                        switchMap(timer =>
                            http.get<any>(`${environment.NApiDomain}/student-data/${token}/`)
                        )
                    );
            } else {
                const blankData: AllData = {
                    classData: [],
                    assessmentData: [],
                    questionData: [],
                    choiceData: [],
                    attemptData: [],
                    feedbackData: [],
                    preferencesData: [],
                    rubricData: [],
                };
                return observableOf(blankData);
            }
        }));

        this.setData();
    }

    private setData(): Promise<void> {
        return new Promise(resolve => {
            // Attach feedback and attempt to question data
            this.data = this.data.pipe(map(data => {
                if (data) {
                    // Attach Feedback
                    data.questionData = data.questionData.map(question => {
                        const feedbackItem = data.feedbackData.find(feedback => feedback.QuestionId === question.Id);
                        if (feedbackItem) {
                            question.feedback = feedbackItem;

                            if (feedbackItem.UniqueKey && feedbackItem.ContentType) {
                                if (feedbackItem.MediaConvertStatus) {
                                    question.feedback.url = `${environment.CloudFrontUrl}/${environment.Buckets.attempt}/${feedbackItem.UniqueKey.toLowerCase()}.mp4`;
                                } else {
                                    question.feedback.url = this.processAttemptAttachmentFilePath(
                                        feedbackItem.UniqueKey,
                                        feedbackItem.ContentType
                                    );
                                }
                            }
                        }

                        const attemptItem = data.attemptData.find(attempt => attempt.TaskId === question.Id);
                        if (attemptItem) {
                            question.attempt = attemptItem;
                            if (attemptItem.UniqueKey && attemptItem.ContentType) {
                                if (attemptItem.MediaConvertStatus) {
                                    question.attempt.url = this.buildS3path('.mp4', attemptItem.UniqueKey.toLowerCase(), 'attempt', attemptItem.ContentType, attemptItem.MediaConvertStatus)
                                } else {
                                    question.attempt.url = this.processAttemptAttachmentFilePath(
                                        attemptItem.UniqueKey,
                                        attemptItem.ContentType
                                    );
                                }
                            }
                        }

                        if (question.ContentType && question.UniqueKey && question.Extension) {
                            if (question.ContentType.includes('video')) {
                                question.hasVideo = true;
                                if (question.MediaConvertStatus) {
                                    if (question.MediaConvertStatus === 'COMPLETE') {
                                        question.videoUrl = this.processQuestionAttachmentFilePath(
                                            question.UniqueKey,
                                            '.mp4'
                                        );
                                    } else {
                                        question.QuestionMediaFilesNotReady = true;
                                    }
                                } else {
                                    question.videoUrl = this.processQuestionAttachmentFilePath(
                                        question.UniqueKey,
                                        question.Extension
                                    );
                                }
                            } else if (question.ContentType.includes('audio')) {
                                question.hasAudio = true;
                                if (question.MediaConvertStatus) {
                                    if (question.MediaConvertStatus === 'COMPLETE') {
                                        question.audioUrl = this.processQuestionAttachmentFilePath(
                                            question.UniqueKey,
                                            '.mp4'
                                        );
                                    } else {
                                        question.QuestionMediaFilesNotReady = true;
                                    }
                                } else {
                                    question.audioUrl = this.processQuestionAttachmentFilePath(
                                        question.UniqueKey,
                                        question.Extension
                                    );
                                }
                            } else if (question.ContentType.includes('image')) {
                                question.imageUrl = this.processQuestionAttachmentFilePath(
                                    question.UniqueKey,
                                    question.Extension
                                );
                            }
                        }

                        if (data.choiceData && data.choiceData.length > 0) {
                            let choiceItems = new Array<Choice>();
                            for (let choice of data.choiceData) {
                                if (choice.ExerciseId == question.Id) {
                                    if (choice.Content) {
                                        if (!choice.Content.type.includes('image') && choice.Content.MediaConvertStatus) {
                                            if (choice.Content.MediaConvertStatus === 'COMPLETE') {
                                                choice.Content.url = `${environment.CloudFrontUrl}/${environment.Buckets.questionAttachment}/${choice.Content.UniqueKey.toLowerCase()}.mp4`;
                                            } else {
                                                question.QuestionMediaFilesNotReady = true;
                                            }
                                        } else {
                                            choice.Content.url = this.buildS3path(choice.Content.Extension, choice.Content.UniqueKey, 'questionAttachment')
                                        }
                                    }
                                    choiceItems.push(choice);
                                }
                            }
                            question.Choices = choiceItems;
                        }
                        return question;
                    });

                    data.questionData.reverse().map(question => {
                        // Check for a double-prompts question (image+audio for example) and put both content URLs in the first one
                        if (!this.previousQuestion) {
                            this.previousQuestion = question;
                        }
                        if (question.Id === this.previousQuestion.Id) {
                            if (this.previousQuestion.audioUrl) {
                                question.hasAudio = true;
                                question.audioUrl = this.previousQuestion.audioUrl;
                            } else {
                                if (this.previousQuestion.imageUrl) {
                                    question.imageUrl = this.previousQuestion.imageUrl;
                                } else {
                                    if (this.previousQuestion.videoUrl) {
                                        question.hasVideo = true;
                                        question.videoUrl = this.previousQuestion.videoUrl;
                                    }
                                }
                            }
                        }
                        this.previousQuestion = question;
                        return question;
                    });

                    this.flag = 0;
                    data.questionData.reverse().map(question => {
                        // Set the ContentType property of the second one to 'skip' so that we know that this question shouldn't be displayed
                        if (this.flag === 1) {
                            question.ContentType = 'skip';
                            this.flag = 0;
                        } else {
                            if (
                                (question.hasVideo && question.hasAudio) ||
                                (question.hasVideo && question.imageUrl) ||
                                (question.hasAudio && question.imageUrl)
                            ) {
                                this.flag = 1;
                            }
                        }
                        return question;
                    });

                    // Filter questions with type skip and the ones that are incomplete
                    // Shuffle questions if randomize questions is on
                    data.assessmentData = data.assessmentData.map(assessment => {
                        assessment.questions = data.questionData.filter(x => x.SkillId === assessment.Id && x.ContentType != 'skip' && !this.isQuestionIncomplete(x));
                        if (assessment.IsRandomQuestions) {
                            assessment.questions = shuffle(assessment.questions);
                        }
                        return assessment;
                    });

                    // Add images to all classes
                    data.classData = data.classData.map(item => {
                        if (item.UniqueKey) {
                            item['src'] = `${environment.CloudFrontUrl}/${environment.Buckets.class}/${item.UniqueKey.toLowerCase()}${item.Extension}`;
                        }
                        return item;
                    });
                }
                resolve();
                return data;
            }), shareReplay(1));

            this.data.subscribe(allData => {
                this.rawData = allData;
            });

            this.classes = this.data.pipe(map(data => data.classData));
            this.assessments = this.data.pipe(map(data => data.assessmentData.sort((a, b) => a.ShowOrder - b.ShowOrder)));
            this.questions = this.data.pipe(map(data => data.questionData));
            this.choices = this.data.pipe(map(data => data.choiceData));
            this.preferences = this.data.pipe(map(data => data.preferencesData));
            this.rubric = this.data.pipe(map(data => data.rubricData));
            this.feedback = this.data.pipe(map(data => data.feedbackData));
        });
    }

    refreshData(): Promise<boolean> {
        return new Promise(resolve => {
            if (this.token) {
                this.data = this.http.get<any>(`${environment.NApiDomain}/student-data/${this.token}/`);
                this.setData().then(() => {
                    resolve(true);
                });
            } else {
                console.log('No token was found on refreshData');
                resolve(false);
            }
        });
    }

    setResponseType(responseType: number): string {
        switch (responseType) {
            case 1: {
                return 'video';
            }
            case 2: {
                return 'audio';
            }
            case 3: {
                return 'text';
            }
            case 4: {
                return 'mc';
            }
            case 5: {
                return 'ap';
            }
            case 6: {
                return 'screenAndCamera'
            }
            case 7: {
                return 'screenAndMicrophone'
            }
        }
    }

    setFriendlyReponseType(responseType: number): any {
        switch (responseType) {
            case 1: {
                return { value: 'video', name: 'video' };
            }
            case 2: {
                return { value: 'audio', name: 'audio' };
            }
            case 3: {
                return { value: 'text', name: 'written' };
            }
            case 4: {
                return { value: 'mc', name: 'multiple choice' };
            }
            case 5: {
                return { value: 'ap', name: 'simulated conversation' };
            }
            case 6: {
                return {value: 'screenAndCamera', name: 'share screen and camera'};
            }
            case 7: {
                return {value: 'screenAndMicrophone', name: 'share screen and microphone'};
            }
        }
    }

    processQuestionAttachmentFilePath(key: string, extension: string) {
        return `${environment.CloudFrontUrl}/${environment.Buckets.questionAttachment}/${key.toLowerCase()}${extension}`;
    }

    // Feedback lives in the attempt bucket.
    processAttemptAttachmentFilePath(key: string, contentType: string) {
        let extension = '.m4a';
        if (contentType === 'audio/wav') {
            extension = '.wav';
        } else if (contentType === 'video/mp4') {
            extension = '.mp4';
        } else if (contentType === 'text/txt') {
            extension = '.txt';
        }
        return `${environment.CloudFrontUrl}/${environment.Buckets.attempt}/${key.toLowerCase()}${extension}`;
    }

    findClass(id: string) {
        return this.classes.pipe(map(list => list.find(item => item.IdGuid === id)));
    }

    /**
     * These aren't static because you can't access static services from a template
     */
    public isQuestionSubmitted(assessment: Assessment, question: Question) {
        if (question.RubricId || assessment.IsGradable) {
            return (question.attempt || question.AttemptStatus === 1 || question.AttemptStatus === 5 || question.AttemptStatus === 6) && (!question.feedback || !question.feedback.scores || question.feedback.scores.length === 0);
        } else {
            return question.attempt || question.AttemptStatus === 1 || question.AttemptStatus === 5 || question.AttemptStatus === 6;
        }
    }

    public isQuestionGraded(assessment: Assessment, question: Question) {
        if (question.IsMultipleChoice) {
            return this.isQuestionSubmitted(assessment, question) && assessment.IsPublished;
        } else if (question.RubricId) {
            return question.feedback && question.feedback.scores && question.feedback.scores.length > 0;
        } else {
            return question.feedback && question.feedback.Date;
        }
    }

    public isQuestionCompleted(acceptLateWork: boolean, assessment: Assessment) {
        return !acceptLateWork && moment(assessment.EndTime).fromNow().includes('ago');
    }

    public isQuestionLocked(assessment: Assessment, question: Question) {
        if (!assessment || assessment.AssessmentType) {
            return false;
        }

        if (question.IsReRecording) {
            return false;
        }

        if (!question.NumberOfAttempts) {
            if (question.AttemptStatus === 8) {
                return true;
            } else {
                return false;
            }
        } else {
            if (!question.RecordingsMade || question.RecordingsMade < question.NumberOfAttempts) {
                return false;
            } else {
                return true;
            }
        }
    }

    /**
     * The assessment can be locked if it has grades and is not re-recordable.
     */
    public isLockable(assessment: Assessment, question?: Question) {
        // Sometimes we get a null assessment:
        if (!assessment || !question) {
            return false;
        }

        if (assessment.AssessmentType) {
            return false;
        } else {
            return !question.IsReRecording;
        }
    }

    public isQuestionAvailable(question: Question) {
        if (question.attempt) {
            return false;
        }

        if (question.IsReRecording) {
            return true;
        }

        if (question.NumberOfAttempts) {
            if (!question.RecordingsMade || question.RecordingsMade < question.NumberOfAttempts) {
                return true;
            } else {
                return false;
            }
        } else {
            if (question.AttemptStatus === 8) {
                return false;
            } else {
                return true;
            }
        }
    }

    public numberOfAttemptsLeft(question: Question) {
        if (question.IsReRecording) {
            return null;
        }

        if (!question.NumberOfAttempts) {
            return 1;
        }

        if (question.RecordingsMade) {
            return question.NumberOfAttempts - question.RecordingsMade;
        } else {
            return question.NumberOfAttempts;
        }
    }

    public friendlyNumberOfAttemptsLeftMessage(question: Question, showAsAttemptsLeft?: boolean) {
        if (question.IsReRecording) {
            return 'Unlimited attempts';
        }

        if (!question.NumberOfAttempts) {
            return 'Single attempt';
        }

        if (question.RecordingsMade) {
            const recordingsLeft = (question.NumberOfAttempts - question.RecordingsMade) >= 0 ? question.NumberOfAttempts - question.RecordingsMade : 0;
            return `${recordingsLeft} ${recordingsLeft === 1 ? 'attempt' : 'attempts'}${showAsAttemptsLeft ? ' left' : ''}`;
        } else {
            return `${question.NumberOfAttempts} attempts${showAsAttemptsLeft ? ' left' : ''}`;
        }
    }

    public isReRecordable(question: Question) {
        if (!question) {
            return false;
        }

        if (question.IsReRecording) {
            return true;
        }

        if (!question.NumberOfAttempts) {
            return false;
        }

        if (!question.RecordingsMade || question.RecordingsMade < question.NumberOfAttempts) {
            return true;
        } else {
            return false;
        }
    }

    public isQuestionIncomplete(question: any): boolean {
        if (question.IsMultipleChoice) {
            if (question.Choices?.length === 0) {
                return true;
            }
            return false;
        } else if (question.APMode || question.ResponseType === 5) {
            return !question.audioUrl;
        } else {
            return false;
        }
    }

    public buildS3path(extension: any, uniqueKey: any, bucket: any, contentType?: string, mediaConvertStatus?: string) {
        if (mediaConvertStatus === 'ERROR') {
            const type = contentType.includes('video') ? 'video' : (contentType.includes('audio') ? 'audio' : null);
            return `${environment.CloudFrontUrl}/${environment.Buckets[bucket]}/attempts/${type}/${uniqueKey.toLowerCase()}`;
        } else {
            return `${environment.CloudFrontUrl}/${environment.Buckets[bucket]}/${uniqueKey.toLowerCase()}${extension}`;
        }
    }

    public refresh() {
        this.refresher.next(null);
    }

    public doRefresh(event) {
        this.refreshData().then(() => {
            event.target.complete();
        });
    }

    public transformSecondsToMinAndSec(totalSeconds: number) {
        totalSeconds = Math.ceil(totalSeconds);
        let result = '';
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = totalSeconds % 60;

        if (hours) {
            result += `${hours} h, `;
        }
        if (minutes) {
            result += `${minutes} min`;
        }
        if (minutes && seconds) {
            result += ' and '
        }
        if (seconds) {
            result += `${seconds} sec`;
        }

        return result;
    }

    public checkQuestion(assessment: any, question: any, lateWork: boolean, classId: string, userName: string, userId: any): Promise<any> {
        return new Promise(resolve => {
            this.http.get(
                `${environment.NApiDomain}/${this.token}/classes/${classId}/questions/${question.Id}/check/${userName}/${userId}`
            ).subscribe((result: any) => {
                if (!result.success) {
                    console.log(result.error);
                    resolve({ success: false, error: result.error });
                    return;
                }

                if (result.responseType != question.ResponseType) {
                    resolve({ success: false, error: 'Seems like the instructor has changed the Response Type of this question in the meantime.' });
                    return;
                }

                if (result.name != question.Name) {
                    resolve({ success: false, error: 'Seems like the instructor has changed the Question Title in the meantime.' });
                    return;
                }

                if ((result.description || question.Description) && result.description != question.Description) {
                    resolve({ success: false, error: 'Seems like the instructor has changed the Question Text in the meantime.' });
                    return;
                }

                if (assessment.StartTime != result.startTime && result.startTime > moment().valueOf()) {
                    resolve({ success: false, error: 'Seems like the instructor has changed the start time of this assessment in the meantime.' });
                    return;
                }

                if (!lateWork && assessment.EndTime != result.endTime && result.endTime < moment().valueOf()) {
                    resolve({ success: false, error: 'Seems like the instructor has changed the end time of this assessment in the meantime.' });
                    return;
                }

                if (result.reviewTime || question.ReviewTime) {
                    if (result.reviewTime != question.ReviewTime) {
                        resolve({ success: false, error: 'Seems like the instructor has changed the Review Time of this question in the meantime.' });
                        return;
                    }

                    if (!!result.hideQuestion != !!question.HideQuestion) {
                        resolve({ success: false, error: 'Seems like the instructor has changed the Review Time of this question in the meantime.' });
                        return;
                    }
                }

                if (result.responseTime || question.RespondTime) {
                    if (result.responseTime != question.RespondTime) {
                        resolve({ success: false, error: 'Seems like the instructor has changed the Response Time of this question in the meantime.' });
                        return;
                    }

                    if ((result.responseType == 1 || result.responseType == 2) && result.minimumResponse != question.MinimumResponse) {
                        resolve({ success: false, error: 'Seems like the instructor has changed the Minimum Response value of this question in the meantime.' });
                        return;
                    }
                }

                if (result.responseType == 4) {
                    if (result.mcPoints != question.MultipleChoicePoints) {
                        resolve({ success: false, error: 'Seems like the instructor has changed the Points value of this Multiple Choice question in the meantime.' });
                        return;
                    }

                    if (result.mcChoices.length != question.Choices.length) {
                        resolve({ success: false, error: 'Seems like the instructor has changed the number of choices in this Multiple Choice question in the meantime.' });
                        return;
                    }

                    for (let choice of result.mcChoices) {
                        const choiceFromDB = question.Choices.find(c => c.Id == choice.Id);
                        if (!choiceFromDB) {
                            resolve({ success: false, error: 'Seems like the instructor has changed the content of the choices in this Multiple Choice question in the meantime.' });
                            return;
                        }

                        if (choice.CorrectAnswer != choiceFromDB.CorrectAnswer) {
                            resolve({ success: false, error: 'Seems like the instructor has changed the correct choice of this Multiple Choice question in the meantime.' });
                            return;
                        }

                        if ((choice.Text || choiceFromDB.Text) && choice.Text !== choiceFromDB.Text) {
                            resolve({ success: false, error: 'Seems like the instructor has changed the text of the choices in this Multiple Choice question in the meantime.' });
                            return;
                        }

                        if ((choice.UniqueKey || choiceFromDB.Content?.UniqueKey) && choice.UniqueKey !== choiceFromDB.Content.UniqueKey) {
                            resolve({ success: false, error: 'Seems like the instructor has changed the media files of the choices in this Multiple Choice question in the meantime.' });
                            return;
                        }
                    }
                }

                if (result.reRecording != question.IsReRecording || result.numberOfAttempts != question.NumberOfAttempts) {
                    resolve({ success: false, error: 'Seems like the instructor has changed the number of attempts allowed for this question in the meantime.' });
                    return;
                }

                if ((question.videoUrl || question.audioUrl || question.YoutubeVideoId) && !!result.replayMedia != !!question.ReplayMedia) {
                    resolve({ success: false, error: 'Seems like the instructor has changed the Replay Media value of this question in the meantime.' });
                    return;
                }

                if (result.responseType == 5) {
                    if (result.apDirections != question.APDirections) {
                        resolve({ success: false, error: 'Seems like the instructor has changed the Question Directions in the meantime.' });
                        return;
                    }

                    if (result.apIntroduction != question.APIntroduction) {
                        resolve({ success: false, error: 'Seems like the instructor has changed the Question Introduction in the meantime.' });
                        return;
                    }
                }

                if ((result.youtubeVideoId || question.YoutubeVideoId) && result.youtubeVideoId != question.YoutubeVideoId) {
                    resolve({ success: false, error: 'Seems like the instructor has changed the Youtube Media Prompt of this question in the meantime.' });
                    return;
                }

                if ((result.imagePrompt || question.imageUrl) && (!result.imagePrompt || !question.imageUrl || !question.imageUrl.toUpperCase().includes(result.imagePrompt.toUpperCase()))) {
                    if (result.responseType == 5) {
                        resolve({ success: false, error: 'Seems like the instructor has changed the Question Outline of this Simulated Conversation question in the meantime.' });
                        return;
                    } else {
                        resolve({ success: false, error: 'Seems like the instructor has changed the Image Prompt of this question in the meantime.' });
                        return;
                    }
                }

                if ((result.audioPrompt || question.audioUrl) && (!result.audioPrompt || !question.audioUrl || !question.audioUrl.toUpperCase().includes(result.audioPrompt.toUpperCase()))) {
                    resolve({ success: false, error: 'Seems like the instructor has changed the Audio Prompt of this question in the meantime.' });
                    return;
                }

                if ((result.videoPrompt || question.videoUrl) && (!result.videoPrompt || !question.videoUrl || !question.videoUrl.toUpperCase().includes(result.videoPrompt.toUpperCase()))) {
                    resolve({ success: false, error: 'Seems like the instructor has changed the Video Prompt of this question in the meantime.' });
                    return;
                }

                if (!result.reRecording && result.activityStatus && result.activityStatus == 8) {
                    resolve({ success: false, error: 'Sorry, this question is locked.' });
                    return;
                }

                if (!result.reRecording && result.recordingsMade >= result.numberOfAttempts) {
                    resolve({ success: false, error: 'Sorry, this question is locked.' });
                    return;
                }

                resolve({ success: true });
            }, err => {
                resolve({ success: false, error: err.message });
            });
        });
    }

    public firstUnansweredQuestion(assessment: any, acceptLateWork: boolean, questions: any) {
        for (let i = 0; i < questions.length; i++) {
            if (this.deviceService.isApp() && (questions[i].ResponseType === 6 || questions[i].ResponseType === 7)) {
                continue;
            }

            if (
                !this.isQuestionGraded(assessment, questions[i]) &&
                !this.isQuestionSubmitted(assessment, questions[i]) &&
                !this.isQuestionLocked(assessment, questions[i]) &&
                !this.isQuestionCompleted(acceptLateWork, assessment)
            ) {
                return questions[i];
            }
        }

        return null;
    }

    public nextUnansweredQuestion(assessment: any, acceptLateWork: boolean, currentQuestion: any, questions: any) {
        let currentQuestionIndex = questions.findIndex(x => x.Id === currentQuestion.Id);
        if (currentQuestionIndex === -1) {
            return null;
        }

        for (let i = 0; i < questions.length - 1; i++) {
            let pointer = (i + currentQuestionIndex + 1) % questions.length;
            if (this.deviceService.isApp() && (questions[pointer].ResponseType === 6 || questions[pointer].ResponseType === 7)) {
                continue;
            }

            if (
                !this.isQuestionGraded(assessment, questions[pointer]) &&
                !this.isQuestionSubmitted(assessment, questions[pointer]) &&
                !this.isQuestionLocked(assessment, questions[pointer]) &&
                !this.isQuestionCompleted(acceptLateWork, assessment)
            ) {
                return questions[pointer];
            }
        }

        return null;
    }

    public neededPermissionsForAssessment(questions: any): any {
        let neededChecksForQuestions: {camera: boolean, microphone: boolean, keyman: boolean} = {camera: false, microphone: false, keyman: false};
        for (let question of questions) {
            const questionResponseType = this.setResponseType(question.ResponseType);
            if (questionResponseType === 'video') {
                neededChecksForQuestions.camera = true;
            } else if (questionResponseType === 'audio' || questionResponseType === 'ap') {
                neededChecksForQuestions.microphone = true;
            } else if (questionResponseType === 'text') {
                neededChecksForQuestions.keyman = true;
            }
        }

        return neededChecksForQuestions;
    }
}
