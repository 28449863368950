import { Component, Input, OnInit } from '@angular/core';
import { Event, ActivatedRoute, RouterEvent, Router } from '@angular/router';

import { AuthService } from './auth.service';
import { environment } from 'environments/environment';

import { DataService } from '../student/data.service';
import { SettingsService } from './settings.service';
import { DeviceService } from './device.service';
import { CurrentModuleService } from 'app/current-module.service';
import { AdminAuthService } from 'app/admin/admin-auth.service';
import { MenuController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { NavService } from './nav.service';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from './account.service';
import { Location } from '@angular/common';
import { LoaderService } from './loader.service';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs/operators';

export interface Class {
    name: string;
    assessments: Assessment[];
}

export interface Assessment {
    name: string;
}

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
})
export class ToolbarComponent implements OnInit {
    @Input() link = 'https://extemporeapp.com/';
    @Input() returnToCMS = false;
    @Input() showOptionsObservable;
    environment = environment;

    classes;
    assessments;
    appVersion: any;

    logoURL: string;
    newAccount: boolean = false;
    showStudentMenu: boolean = true;
    currentModule: string;

    profileImage: string;
    isLoggedIn: boolean;

    activeGradebookItem: string;
    showGradebookInMenu: boolean;
    showRubricsInMenu: boolean
    showReportsInMenu: boolean;
    showManageUsersInMenu: boolean;

    instructorToken: string;

    showAdminLogout: boolean;

    constructor(
        public data: DataService,
        public auth: AuthService,
        public route: ActivatedRoute,
        public router: Router,
        public dialog: MatDialog,
        public settingsService: SettingsService,
        public moduleService: CurrentModuleService,
        public sideNav: MenuController,
        public http: HttpClient,
        public device: DeviceService,
        public navService: NavService,
        public acountService: AccountService,
        private location: Location,
        private loaderService: LoaderService,
        private cookieService: CookieService,
        private adminAuthService: AdminAuthService
    ) {
        this.moduleService.getCurrentModule().subscribe(module => {
            this.currentModule = module;
        });

        // TODO The display name currently can come from Firebase directly, it should all come from the NAPI
        this.classes = data.classes;
        this.assessments = data.assessments;
        this.device.getAppVersion().then(res => {
            this.appVersion = res;
        });

        this.acountService.profilePicture.subscribe(data => {
            this.profileImage = data;
        });

        settingsService.organizationData.subscribe(data => {
            if (data && data.Whitebranding && data.Logo) {
                this.logoURL = data.Logo;
            } else {
                this.logoURL = null;
            }
        });

        this.router.events.subscribe(e => {
            if (e) {
                if (this.sideNav.isOpen) {
                    this.sideNav.close('sideNav');
                }
            }
        });

        if (localStorage.getItem('passwordAuthData')) {
            this.isLoggedIn = true;
        } else {
            this.isLoggedIn = false;
        }
    }

    ngOnInit() {
        this.setupToolbarActiveMenu();
    }

    setupToolbarActiveMenu() {
        let currentURL = this.location.path();

        if (currentURL.includes('commons')) {
            this.activeGradebookItem = 'commons';
            this.checkNewGradebookNewRubrics();
        } else if (currentURL.includes('gradebook')) {
            this.activeGradebookItem = 'gradebook';
            this.checkNewGradebookNewRubrics();
        } else if (currentURL.includes('rubric')) {
            this.activeGradebookItem = 'rubric';
            this.checkNewGradebookNewRubrics();
        } else if (currentURL.includes('district-reports')) {
            this.activeGradebookItem = 'district-reports';
            this.checkNewGradebookNewRubrics();
        } else if (currentURL.includes('manage-users')) {
            this.activeGradebookItem = 'manage-users';
            this.checkNewGradebookNewRubrics();
        }

        if (currentURL.includes('admin/auth')) {
            this.showAdminLogout = false;
        } else {
            this.showAdminLogout = true;
        }
    }

    checkNewGradebookNewRubrics() {
        this.instructorToken = this.cookieService.get('ext-user');

        if (this.instructorToken) {
            this.loaderService.showLoading();
            this.http.get<any>(`${environment.NApiDomain}/instructor/is-new-gradebook-enabled/${this.instructorToken}`).pipe(take(1)).subscribe(res => {
                if (res.success) {
                    this.showGradebookInMenu = res.newGradebook;
                    this.showRubricsInMenu = res.newRubrics;
                    this.showReportsInMenu = res.reportsEnabled;
                    this.showManageUsersInMenu = res.manageUsers;
                } else {
                    console.log('Error', res.error);
                }
                this.loaderService.hideLoading();
            });
        }
    }

    navigate(url: string) {
        if (url === 'rubric') {
            this.router.navigate(['instructor', url, 'list']);
        } else {
            window.location.assign(`${environment.NapiStudentDomain}/instructor/${url}`);
        }
        this.activeGradebookItem = url;
    }

    goBack() {
        let currentRoute = this.location.path();
        if (currentRoute.includes('sync-room') && !currentRoute.includes('lobby') && !currentRoute.includes('grades')) {
            this.navService.syncRoomBack.next(true);
        } else {
            this.navService.back();
        }
    }

    goHome() {
        this.navService.back();
        this.router.navigate(['/']);
    }

    getLogoUrl(filename) {
        return `${environment.CloudFrontUrl}/${environment.Buckets.logo}/${filename}`
    }

    logout() {
        this.auth.logout(true);
    }

    logoClicked() {
        if (this.newAccount) {
            window.location.assign('https://www.extemporeapp.com');
        } else if (!this.auth.credentialsChange) {
            window.location.assign(environment.CMSDomain);
        } else {
            this.goToCMS();
        }
    }

    adminLogout() {
        this.adminAuthService.logout();
    }

    goToCMS() {
        window.open(environment.CMSDomain);
    }

    goToCmsMenu(path: string) {
        location.href = `${environment.CMSDomain}/${path}`;
    }
}
