<style>
    .container > * + * {
        margin-top: 10px;
    }
</style>

<div class="container">
    <div>Are you a part of an Extempore organization?</div>
    <select [(ngModel)]="isOrganization">
        <option [ngValue]="true">Yes</option>
        <option [ngValue]="false">No</option>
    </select>
    <div *ngIf="isOrganization">
        <input type="text" [(ngModel)]="orgAcronym" placeholder="Organization acronym" />
    </div>
    <select [(ngModel)]="role">
        <option value="initial" hidden>Your role</option>
        <option value='Chair/Coordinator'>Chair/Coordinator</option>
        <option value='Teacher'>Teacher</option>
        <option value='Technologist'>Technologist</option>
        <option value='Other'>Other</option>
    </select>
    <div *ngIf="error" class="error">{{error}}</div>
    <div mat-dialog-actions style="float:right;">
        <ion-button (click)="done()" color="primary">Done</ion-button>
    </div>
</div>
