<style>
  ion-fab {
    right: 30px;
  }

  .header-instructor-student-border {
    border-top: 5px solid var(--green);
    border-right: 5px solid var(--green);
    border-left: 5px solid var(--green);
  }

  .content-instructor-student-border {
    border-left: 5px solid var(--green);
    border-right: 5px solid var(--green);
  }

  @media only screen and (max-width: 600px) {
    ion-fab {
      right: 0px;
    }
  }
</style>

<div style="display:none;">{{environment.name}} environment</div>
<div id="main-app">
  <div *ngIf="!offlineStatus && !lowInternetConnection; else noInternet">
    <ion-app [ngClass]="deviceService.getPlatform() === 'ios' ? 'app-margin' : ''">
        <toolbar *ngIf="displayHeader" [ngClass]="isInstructorStudent && currentModule === 'studentModule' ? 'header-instructor-student-border' : ''"></toolbar>
        <ion-content class="displayHeader" [ngClass]="isInstructorStudent && currentModule === 'studentModule' ? 'content-instructor-student-border' : ''">
            <router-outlet id="userMenu">
            </router-outlet>
            <ion-fab *ngIf="showFab && ssoUser" (click)="addClass()" vertical="bottom" horizontal="end" slot="fixed">
            <ion-fab-button color="success">
                <ion-icon name="add"></ion-icon>
            </ion-fab-button>
            </ion-fab>
        </ion-content>
        <ion-footer *ngIf="!deviceService.isOnMobile() && displayFooter">
            <ion-toolbar>
                <instructor-student-footer *ngIf="isInstructorStudent && currentModule === 'studentModule'"></instructor-student-footer>
                <marketing-footer></marketing-footer>
            </ion-toolbar>
        </ion-footer>
    </ion-app>
  </div>
  <ng-template #noInternet>
    <no-network [lowInternetConnection]="lowInternetConnection" [offlineStatus]="offlineStatus"></no-network>
  </ng-template>
</div>
