<style>
    div {
        height: 100%;
    }

    h1 {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10em;
    }
</style>

<div>
    <h1>{{i}}</h1>
</div>