<style>
    img {
        height: 100px;
    }
</style>
<div id="page">
    <img [src]="extemporeLogo">
    <div *ngIf="offlineStatus">
        <h1>You seem to be offline!</h1>
        <p>
            Extempore needs network connection in order to work correctly. <br/>
            Please connect to an internet network so you can continue. <br/>
            Once you get internet connectivity you will get back to the last screen you were seeing.
        </p>
    </div>
    <div *ngIf="lowInternetConnection">
        <h1>Insufficient network speed detected!</h1>
        <p>Your answers might not upload correctly. <br/>
        Please move to an area with better connectivity to use Extempore. <br/>
        Once your internet connectivity is better you will get back to the last screen you were seeing.</p>
    </div>
</div>