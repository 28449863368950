<style>
    .marketing {
        display: flex;
        justify-content: space-between;
        text-align: center;
        padding: 10px 0px;
        max-width: 920px;
        margin: 0px auto;
        align-items: center;
    }

    .marketing p {
        padding: 0px;
        margin-bottom: 0px;
    }
</style>
<div *ngIf="!deviceService.isApp()">
    <footer class="marketing">
        <div>
            <p style="font-size:13px;font-weight:800;">
                <a target="_blank" style="color:#808c91;" href="https://extemporeapp.com/terms-of-service/">Terms of Service </a> |
                <a style="color:#808c91; margin-left:0;" target="_blank" href="https://extemporeapp.com/privacy-policy/">Privacy</a>                |
                <a style="color:#808c91; margin-left:0;" target="_blank" href="https://extemporeapp.com/our-team/">About</a>
            </p>
            <p style="font-family: 'Muli', sans-serif;color: #808c91;margin-left: 10px;font-size:11px;">&copy; {{environment.copyrightYear}} Deeloh Technologies, Inc</p>
        </div>
        <div>
            <div class="store-link-img">
                <a target="_blank" href="https://itunes.apple.com/us/app/extempore/id1062018455">
                            <img class="app-store-img" [src]="environment.CloudFrontUrl + '/' +  environment.Buckets.assets + '/logos/appstore.svg'" alt="app-store">
                        </a>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.extemporeapp.mobile&hl=en_US">
                            <img class="google-play-img" [src]="environment.CloudFrontUrl + '/' +  environment.Buckets.assets + '/logos/gplay.svg'" alt="google-play">
                        </a>
            </div>
        </div>
        <div>
            <div>
                <a href="https://extemporeapp.com/extempore-support-form/" target="_blank">Contact Support</a>
                <a target="_blank" href="https://www.facebook.com/extemporeapp/" style="margin-left:16px;">
                            <img style="vertical-align: middle" [src]="environment.CloudFrontUrl + '/' +  environment.Buckets.assets + '/logos/fb-icon.svg'" alt="facebook">
                        </a>
                <a target="_blank" href="https://twitter.com/ExtemporeApp">
                            <img style="vertical-align: middle" [src]="environment.CloudFrontUrl + '/' +  environment.Buckets.assets + '/logos/tweeter-icon.svg'" alt="tweeter">
                        </a>
            </div>
        </div>
    </footer>
</div>
