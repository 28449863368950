<style>
    .modal-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 16px;
    }

    .modal-header {
        font-weight: bold;
        color:var(--light-main);
        margin: 0;
    }

    .error-text {
        color: var(--ion-color-danger);
    }

    .input-container {
        display: flex;
        flex-direction: column;
        height: 90%;
    }

    .modal-buttons {
        display: flex;
        justify-content: space-between;
    }
</style>

<div class="modal-container">
    <div class="input-container">
        <p class="modal-header">Please insert the link to the class you want to enroll in</p>
        <input mat-input placeholder="Class link" [(ngModel)]="classLink" type="text">    
        <p class="error-text" *ngIf="error">{{error}}</p>
    </div>
    <div class="modal-buttons">
        <ion-button color="danger" (click)="closeModal()">Cancel</ion-button>   
        <ion-button color="success" (click)="addClass()">Add Class</ion-button>  
    </div>
</div>