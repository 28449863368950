<style>

    .gradebook-instructor-logo img {
        max-height: 45px;
    }

    .gradebook-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
    }

    .gradebook-menu> * + * {
        margin-left: 5px;
    }

    .gradebook-menu-item {
        cursor: pointer;
        font-family: "Montserrat";
        font-weight: 400;
        padding: 10px 20px;
        border-bottom: 5px solid transparent;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: left;
    }

    .gradebook-menu-item:not(.gradebook-menu-item-active):hover {
        border-bottom: 5px solid rgba(38, 172, 224, 0.5);
        border-radius: 5px;
    }

    .gradebook-menu-item-active {
        border-bottom: 5px solid var(--gradebook-light-blue);
        border-radius: 5px;
        font-weight: 700;
    }

    .intercom-help {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .intercom-help span {
        font-size: 25px;
        font-weight: bold;
        color: var(--gradebook-gray);
        cursor: pointer;
    }

    .menu {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    .popup-option-button {
        cursor: pointer;
        background: none;
        color: black;
        border: none !important;
        font-size: 1rem;
        text-transform: unset;
        font-family: "Muli", sans-serif;
    }

    .popup-option-button:hover,
    .popup-option-button:focus {
        background: #f8f8f8;
    }

    button:hover {
        background: unset !important;
    }

    .special-divider {
        margin: 3px auto;
        background-color: var(--dark-main);
        height: 2px;
        width: 95%;
    }

    .logos-content {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .logo {
        max-height: 64px;
    }

    a {
        cursor: pointer;
    }

    .main-header {
        background-color: #f8f8f8;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .instructor-header,
    .admin-header {
        background-color: #f8f8f8;
        position: relative;

        -webkit-box-align: center;
        align-items: center;
        flex-direction: row;
        display: flex;
        flex-shrink: 0;
        min-height: 60px;
        -webkit-box-pack: justify;
        border-bottom: 1px solid rgb(229, 231, 237);
        justify-content: space-between;
        padding: 0px 20px;
    }

    .sidenav-class {
        text-align: start;
        border-bottom: 1px solid rgb(198 204 208 / 69%);
    }

    .menu-button,
    .back-button {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .back-container span {
        font-weight: bold;
        color: var(--light-main);
        cursor: pointer;
    }

    .back-container {
        display: flex;
        align-items: center;
    }

    .menu-button ion-avatar {
        width: 45px;
        height: 45px;
        cursor: pointer;
    }

    .app-logo {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .school-logo {
        width: 175px;
        padding: 10px 0;
        cursor: pointer;
    }

    .instructor-logo,
    .admin-logo {
        width: 50%;
        display: block;
        margin: auto;
        text-align: center;
    }

    .instructor-logo img,
    .admin-logo img {
        padding: 10px 0;
        cursor: pointer;
    }

    .app-logo img:focus,
    .instructor-logo img:focus {
        outline: none;
    }

    .admin-home {
        position: absolute;
        top: 25%;
        left: 5%;
        color: var(--light-main);
        cursor: pointer;
        font-size: 2rem;
    }

    .back-to-cms,
    .admin-logout {
        position: absolute;
        top: 25%;
        right: 5%;
    }

    .app-logo .extempore-logo {
        width: 55px;
        padding: 0px 0px 5px;
        cursor: pointer;
    }

    .app-logo .student-portal-logo {
        width: 170px;
        padding: 10px 0;
        cursor: pointer;
    }

    .sidebar-links {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .profile-details {
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(-65deg, rgba(37, 169, 224, 1) 25%, rgba(8, 194, 131, 1) 75%);
    }

    .app-version {
        margin: 0;
        padding: 10px 0;
        font-family: inherit;
        font-size: 0.95rem;
    }

    .profile-details p {
        padding: 10px 0;
        margin: 10px 0;
        font-size: 1.5rem;
        font-weight: bold;
        color: white;
        cursor: pointer;
    }

    .profile-details ion-avatar {
        cursor: pointer;
    }

    #back-button-active:focus {
        outline: none;
        padding: 10px 10px 10px 0;
        border: 1.5px solid transparent;
        border-radius: 6px;
        background-color: #ebe9e9;
    }

    #back-active:focus {
        outline: none;
        box-shadow: none;
    }

    @media only screen and (max-width: 1000px) {
        .app-logo .extempore-logo {
            width: 45px;
        }
    }

    @media only screen and (max-width: 600px) and (min-width: 400px) {
        .logo {
            max-height: 50px;
        }

        .back-container button {
            padding: 0;
        }
    }

    @media only screen and (max-width: 600px) {
        .back-container button {
            padding: 0;
        }
    }
</style>

<div *ngIf="currentModule === 'studentModule'">
    <ion-header class="main-header">
        <div class="back-button">
            <div id="back-button-active" class="back-container" *ngIf="navService.history.length > 0"
                (click)="goBack()">
                <span class="material-icons">
                    keyboard_arrow_left
                </span>
                <button id="back-active">
                    BACK
                </button>
            </div>
        </div>

        <div class="app-logo">
            <img class="school-logo" *ngIf="logoURL" [src]="getLogoUrl(logoURL)" alt="UniLogo" (click)="goHome()">
            <img [ngClass]="device.isOnMobile() ? 'extempore-logo' : 'student-portal-logo'"
                [src]="device.isApp() || device.isOnMobile() ? '../img/favicon.png' : '../img/ExtemporeStudentPortal-mobile.png'"
                (click)="goHome()" />
        </div>

        <ion-buttons class="menu-button">
            <ion-avatar (click)="sideNav.open()">
                <img [src]="profileImage ? profileImage : 'img/ion-avatar.svg'" />
            </ion-avatar>
        </ion-buttons>
    </ion-header>

    <ion-menu swipe-gesture="true" type="overlay" side="end" content-id="userMenu" menuId="sideNav">
        <div class="profile-details">
            <ion-avatar [routerLink]="['/account']">
                <img [src]="profileImage ? profileImage : 'img/ion-avatar.svg'" />
            </ion-avatar>
            <p [routerLink]="['/account']">{{acountService.fullName}}</p>
        </div>
        <div class="sidebar-links">
            <ion-list>
                <ion-item [routerLink]="['/account']">My Account</ion-item>
                <ion-item [routerLink]="['/preferences']">Notification Preferences</ion-item>
                <!-- <ion-item [routerLink]="['/portfolio']">Student Portfolio</ion-item> -->
                <ion-item [routerLink]="['/video-check']">Camera & Microphone Check</ion-item>
            </ion-list>
            <ion-list>
                <ion-item [routerLink]="['/help']">Help</ion-item>
                <ion-item style="cursor: pointer;" (click)="logout()">Logout</ion-item>
                <p class="app-version" *ngIf="appVersion">Version: {{appVersion}}</p>
            </ion-list>

        </div>
    </ion-menu>
</div>

<div *ngIf="currentModule === 'instructorModule'">
    <ion-header class="instructor-header">
        <div class="instructor-logo">
            <img (click)="goToCMS()"
            [src]="environment.CloudFrontUrl + '/' +  environment.Buckets.assets + '/logos/ExtemporeLogo.png'" alt="Extempore"
                class="logo">
        </div>
        <ion-button class="back-to-cms" fill="clear" [href]="environment.CMSDomain">
            < Back to Classes</ion-button>
    </ion-header>
</div>

<div *ngIf="currentModule === 'instructorModuleNew'">
    <ion-header class="instructor-header">
        <div class="instructor-logo">
            <img (click)="goToCMS()"
            [src]="environment.CloudFrontUrl + '/' +  environment.Buckets.assets + '/logos/ExtemporeLogo.png'" alt="Extempore"
                class="logo">
        </div>
    </ion-header>
</div>

<div *ngIf="currentModule === 'gradebookModule'">
    <div class="instructor-header">
        <div class="gradebook-instructor-logo">
            <img (click)="goToCMS()"
            [src]="environment.CloudFrontUrl + '/' +  environment.Buckets.assets + '/logos/ExtemporeLogo.png'" alt="Extempore"
                class="logo">
        </div>
        <div class="gradebook-menu">
            <div (click)="goToCmsMenu('SkillAreas')" class="gradebook-menu-item"
                [ngClass]="activeGradebookItem === 'classes' ? 'gradebook-menu-item-active' : ''">
                Classes
            </div>
            <div *ngIf="showGradebookInMenu"
                [ngClass]="activeGradebookItem === 'gradebook' ? 'gradebook-menu-item-active' : ''"
                class="gradebook-menu-item" (click)="navigate('gradebook')">
                Gradebook
            </div>
            <div *ngIf="showRubricsInMenu"
                [ngClass]="activeGradebookItem === 'rubric' ? 'gradebook-menu-item-active' : ''"
                class="gradebook-menu-item" (click)="navigate('rubric')">
                Rubrics
            </div>
            <div [ngClass]="activeGradebookItem === 'commons' ? 'gradebook-menu-item-active' : ''"
                (click)="navigate('commons')" class="gradebook-menu-item">
                Content
            </div>
            <div *ngIf="showReportsInMenu"
                [ngClass]="activeGradebookItem === 'district-reports' ? 'gradebook-menu-item-active' : ''"
                (click)="navigate('district-reports')" class="gradebook-menu-item">
                Reports
            </div>
            <div *ngIf="showManageUsersInMenu"
                [ngClass]="activeGradebookItem === 'manage-users' ? 'gradebook-menu-item-active' : ''"
                (click)="navigate('manage-users')" class="gradebook-menu-item">
                Manage users
            </div>
        </div>
        <!-- Empty div so flex will work as intended -->
        <div></div>
    </div>
</div>

<div *ngIf="currentModule === 'none'">
    <ion-header class="main-header">
        <div class="app-logo">
            <img [routerLink]="'/'" [ngClass]="device.isOnMobile() ? 'extempore-logo' : 'student-portal-logo'"
                [src]="device.isOnMobile() ? '../img/favicon.png' : '../img/ExtemporeStudentPortal-mobile.png'" />
        </div>
    </ion-header>
</div>

<div *ngIf="currentModule === 'admin'">
    <ion-header class="admin-header">
        <mat-icon [ngStyle]="{'visibility': showAdminLogout ? 'visible' : 'hidden'}" class="admin-home"
            [routerLink]="['/admin/home']">home</mat-icon>
        <div class="admin-logo">
            <img [src]="environment.CloudFrontUrl + '/' +  environment.Buckets.assets + '/logos/ExtemporeLogo.png'" alt="Extempore"
                class="logo">
        </div>
        <ion-button [ngStyle]="{'visibility': showAdminLogout ? 'visible' : 'hidden'}" fill="clear" class="admin-logout"
            (click)="adminLogout()">Logout</ion-button>
    </ion-header>
</div>

<div *ngIf="currentModule === 'demo'">
    <!-- DO NOTHING! THE TOOLBAR IS COMING FROM NAV COMPONENT -->
</div>
