<style>
    @keyframes pulse {
  0% {
    transform: scale(.15) rotate(180deg);
  }
  100% {
    transform: scale(1);
  }
}

.loader-icon {
  height: 200px;
  width: 200px;
  animation: pulse 2s cubic-bezier(0.5, 0, 0.5, 1) alternate infinite;
}

@media only screen and (max-width: 600px) {
    .loader-icon {
        width: 120px;
        height: 120px;
    }
}

</style>

<img class="loader-icon" src="../img/favicon.png" />